<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row class="d-flex justify-center">
          <v-col cols="12">
            <span class="titlehist">Muro de noticias y comunidad</span>
            <div class="searchbar d-flex flex-row my-4">
              <v-btn
                fab
                small
                elevation="0"
                class="rounded-pill rounded-r-0"
                color="#feb300"
                @click="search=''"
              >
                <v-icon size="20">mdi-magnify</v-icon></v-btn
              >
              <input
                type="text"
                style="width: 300px; border: 0px;"
                placeholder="Buscar publicación por nombre"
                class="pl-3 rounded-pill rounded-l-0"
                v-model="search"
              />
            </div>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown? 12 : 7" class="d-flex justify-center">
            <community-card :posts="filterPost" @changePosts="handleChangePost"> </community-card>
          </v-col>
        </v-row>

      </v-col>
      <!-- <v-divider vertical></v-divider> -->
      <!-- <v-col cols="3">
        <v-card class="pt-3 pr-0 pl-1 pb-15" height="100%">
          <div>
            <v-icon>mdi-star-outline</v-icon>
            <span class="body-2 font-weight-bold">Mis Favoritos</span>
            <br />
            <v-row class="pl-5 pt-5 pr-10">
              <span
                class="body-2 font-weight-bold"
                style="color: #00bcd4; text-decoration: underline"
                >Boletin de actualidad del 1er trimestre de 2023</span
              >
            </v-row>
            <v-row class="pl-5 pt-2">
              <span class="caption font-weight-bold"
                >Publicado el 00-00-0000</span
              >
            </v-row>
            <v-row class="pl-5">
              <span class="caption font-weight-bold">00:00:00 PM</span>
            </v-row>
          </div>
          <br />
          <v-divider horizontal></v-divider>
          <div>
            <br />
          </div>
          <br />
          <br />
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import ActionButton from "../../components/shared/ActionButton.vue";
import CommunityCard from "../../components/memberDashboard/memberCommunity/communityCard.vue";
import DeleteDialog from "../../components/memberDashboard/affiliates/DeleteDialog.vue";
import ModalTemplate from "../../components/shared/ModalTemplate.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import { colors } from "@/constants/colors.js";

export default {
  components: {
    CommunityCard,
  },
  data() {
    return {
      memberPublications:[],
      search: ''
    };
  },
  async mounted(){
    try {
      let response = await this.fetchMemberPublications()
      if (response.status) {
        this.memberPublications = response.data
        this.memberPublications = this.memberPublications.map((publication) => {
          if (publication.type === 'DOC') publication.url = publication.value;
          return {
            ...publication,
            expand: false,
            hideOver: false,
          }
        })
        console.log(this.memberPublications);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions("publications", ["fetchMemberPublications"]),

    handleChangePost({property, value, index}){
      Object.assign(this.memberPublications[index], { [property]: value } )
    }
  },

  computed:{
    filterPost(){
      let reg = new RegExp(this.search, 'i')
      return this.memberPublications.filter(e=> reg.test(e.name)) || this.memberPublications
    }
  }
};
</script>

<style>

input:focus{
  outline: none;
}

.smaller-text {
  font-size: 10px;
}

.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}

.titlehist {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 20px;
}

.super-title {
  font-family: "Montserrat-Black", Fallback, sans-serif;
  font-weight: 800;
  font-size: 35px;
}

.rounded-image {
  border-radius: 15%;
}

.full-height {
  height: 100%;
}

.dialog-margin {
  margin: 20px;
  background-color: #eeeeee;
}
</style>