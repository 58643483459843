<template>
  <v-card class="pa-5">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="mt-2">
          <p class="text-center font-weight-bold headline mb-0">
            Eliminar Afiliado Vinculado
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="title text-center mb-0">
            ¿Está seguro que quiere eliminar el afiliado seleccionado?
          </p>
          <p class="title text-center">
            Los datos de este afiliado no podrán ser recuperados.
          </p>
        </v-col>
      </v-row>
      <v-row
        class="d-flex pb-2 mt-5"
        :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'"
      >
        <v-btn
          class="font-weight-bold black--text mx-2"
          color="grey lighten-3"
          rounded
          elevation="0"
          @click="handleCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          class="font-weight-bold black--text mx-2"
          color="accent"
          rounded
          elevation="0"
          :loading="loading"
          @click="handleDelete()"
        >
          Aceptar
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    affiliate: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleDelete() {
      this.$emit("continue", this.affiliate);
    },
    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>